@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin hover {
  &:hover {
    @content;
  }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color !important;
  }
  a#{$parent} {
    @include hover-focus {
      color: darken($color, 10%) !important;
    }
  }
}

@mixin text-hide($ignore-warning: false) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;

  @if ($ignore-warning != true) {
    @warn "The `text-hide()` mixin has been deprecated as of v4.1.0. It will be removed entirely in v5.";
  }
}

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin button-size(
  $padding-y,
  $padding-x,
  $font-size,
  $line-height,
  $border-radius
) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  line-height: $line-height;
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}

@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }
}

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    transition: none;
  }
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  %grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Prevent columns from collapsing when empty
    padding-right: ($gutter / 2);
    padding-left: ($gutter / 2);
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column;
      }
    }
    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Reset earlier grid tiers
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      @for $i from 0 through ($columns - 1) {
        @if not($infix == "" and $i == 0) {
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

@mixin make-container() {
  width: 100%;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

@mixin make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: ($grid-gutter-width / -2);
  margin-left: ($grid-gutter-width / -2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}
